/* @import url("https://fonts.googleapis.com/css?family=Nothing+You+Could+Do&display=swap"); */
/* NothingYouCouldDo-Regular.tff */
.Portfolio-header {
  /* background-color: #282c34; */
  /* PANTONE 19-4052 Classic Blue
    https://www.pantone.com/color-finder/19-4052-TCX
    */
  background-color: #0f4c81;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.cardActionArea {
  height: 80%;
}
.grid {
  padding: 8px;
}
.card {
  height: 100%;
}
.media {
  height: 140px;
}
/* root: {
   flexGrow: 1,
   padding: theme.spacing(2),
}, */
