.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  /* PANTONE 19-4052 Classic Blue
  https://www.pantone.com/color-finder/19-4052-TCX
  */
  background-color: #0f4c81;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.error-text {
  color: #92892d;
  font-size: calc(10px + 10vmin);
}
