.App {
  text-align: center;
  /* PANTONE 19-4052 Classic Blue
  https://www.pantone.com/color-finder/19-4052-TCX
  */
  background-color: #0f4c81;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.canvas-container {
  width: 100%;
  text-align: center;
}
.video-container {
  width: 900px;
  margin: auto;
  max-width: 80%;
}
.shaka-video {
  width: 100%;
  /* height: 100%; */
}
.video-error-text {
  color: #92892d;
  font-size: calc(10px + 3vmin);
}
