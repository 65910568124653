/* @import url("https://fonts.googleapis.com/css?family=Nothing+You+Could+Do&display=swap"); */

.App {
  text-align: center;
  /* PANTONE 19-4052 Classic Blue
  https://www.pantone.com/color-finder/19-4052-TCX
  */
  background-color: #0f4c81;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.website-name {
  font-family: "Nothing You Could Do";
  color: #92892d;
}
.canvas-container {
  text-align: center;
}
.canvas {
  display: inline;
  padding-left: 32px;
  max-width: 70%;
}
a.contactContainer {
  font-family: "Nothing You Could Do";
  color: #fff;
  font-size: calc(10px + 3vmin);
  text-decoration: none;
  padding: 10px;
  border: 1px solid #fff;
  margin-right: 4px;
  margin-left: 4px;
}
a.contactContainer:hover {
  text-decoration: underline;
}
.buttonRow {
  display: flex;
  flex-direction: row;
}
